import { defineStore, storeToRefs } from 'pinia';
import { PortalServiceTypes } from '@/types/portalService';
import { reactive, ref, watch } from 'vue';
import PortalService from '@/common/services/portal.service';
import { useAuthStore } from './auth';
import { useAccountsStore } from './accounts';
import FeatureFlags = PortalServiceTypes.FeatureFlags;

export const usePartnerStore = defineStore('partner', () => {
  const authStore = useAuthStore();
  const accountStore = useAccountsStore();
  const { user } = storeToRefs(authStore);
  const { account } = storeToRefs(accountStore);
  const showLowBalanceAlert = ref(true);
  const userDismissedBanner = ref(false);
  const reviewBannersDismissed = ref<Record<string, boolean>>({});
  const partner = ref<PortalServiceTypes.Partner>();
  const automatePayout = reactive<PortalServiceTypes.PayoutConfigRequest>({
    type: PortalServiceTypes.balancePayoutType.MANUAL,
  });
  const repeatEnds = ref('');
  const repeatsOn = ref('');
  const enableSaveConfig = ref(false);
  const partnerApiKeys = ref<PortalServiceTypes.ApiKey[]>([]);
  const loading = ref(false);
  const walletManagementEnabled = ref(false);
  const apiKeysManagementEnabled = ref(false);
  const webhooksManagementEnabled = ref(false);
  const whitelistedIpsEnabled = ref(false);
  const enabledCountriesEnabled = ref(false);
  const newIpAddress = ref('');
  const partnerIPAddresses = ref<string[]>([]);
  const deleteIPAddress = ref<string | null>();
  const displayPartnerIPAddresses = ref<any[]>([]);
  const channelsCoverageEnabled = ref(false);
  const partnerRates = ref<PortalServiceTypes.PartnerRate[]>([]);
  const showPendingCountriesBanner = ref(false);
  const selectedCountries = ref<any[]>([]);
  const statusChangedList = ref<any[]>([]);
  const countryStatusChangeBannerHidden = ref(false);
  const isB2BPartner = ref(false);
  const isCommercialPartner = ref(false);

  function resetPartnerStore() {
    partner.value = undefined;
  }

  function resetProductTypes() {
    isB2BPartner.value = false;
    isCommercialPartner.value = false;
  }

  function resetPayoutConfig() {
    automatePayout.type = PortalServiceTypes.balancePayoutType.MANUAL;
    automatePayout.frequency = undefined;
    automatePayout.repeatFrequency = undefined;
    automatePayout.endPayout = undefined;
    automatePayout.endPayoutAfter = undefined;
    automatePayout.weeklyDays = undefined;
    automatePayout.yearlyDate = undefined;
    automatePayout.monthlyDate = undefined;
    automatePayout.endPayoutAfter = undefined;
    automatePayout.endPayoutDate = undefined;
  }

  function setAutomatedPayoutConfigState(
    payoutConfig: PortalServiceTypes.PayoutConfigRequest,
  ) {
    automatePayout.type = payoutConfig.type;
    if (automatePayout.type === PortalServiceTypes.balancePayoutType.MANUAL) {
      resetPayoutConfig();
      return;
    }
    automatePayout.frequency = payoutConfig.frequency;
    automatePayout.repeatFrequency = payoutConfig.repeatFrequency;
    automatePayout.endPayout = payoutConfig.endPayout;
    automatePayout.endPayoutAfter = payoutConfig.endPayoutAfter;
    automatePayout.weeklyDays = payoutConfig.weeklyDays;
    automatePayout.yearlyDate = payoutConfig.yearlyDate;
    automatePayout.monthlyDate = payoutConfig.monthlyDate;
    automatePayout.endPayoutAfter = payoutConfig.endPayoutAfter;
    automatePayout.endPayoutDate = payoutConfig.endPayoutDate;
  }

  function resetLowBalanceBanner() {
    showLowBalanceAlert.value = true;
    userDismissedBanner.value = false;
  }

  function resetReviewWalletBanner() {
    reviewBannersDismissed.value = {};
  }

  function setShowLowBalanceAlert() {
    const usdAccount = account.value.accounts.find(
      item => item.currency.toUpperCase() === 'USD',
    );

    showLowBalanceAlert.value =
      isB2BPartner.value &&
      (usdAccount?.available || 0) <= (partner.value?.lowBalanceLimit || 1000);
  }

  async function initialiseAutomatePayout() {
    if (!user.value || !user.value.token) {
      throw new Error('cannot retrieve partner if user is undefined');
    }
    try {
      const payoutConfig = await PortalService.getAutomatedPayoutConfig(
        user.value.token,
      );
      setAutomatedPayoutConfigState(payoutConfig.payoutConfig);
    } catch (e) {
      // failed at payout config initialization, set to manual and continue
      // TODO relook at all the setup calls that happen before login
      setAutomatedPayoutConfigState({
        type: PortalServiceTypes.balancePayoutType.MANUAL,
      });
    }
  }

  watch(account, () => {
    setShowLowBalanceAlert();
  });

  watch(automatePayout, async newAutomatePayout => {
    if (
      newAutomatePayout.type === PortalServiceTypes.balancePayoutType.AUTOMATED
    ) {
      enableSaveConfig.value = false;
      if (newAutomatePayout.frequency) {
        switch (newAutomatePayout.frequency) {
          case PortalServiceTypes.balancePayoutFrequency.DAILY:
            enableSaveConfig.value = true;
            break;
          case PortalServiceTypes.balancePayoutFrequency.WEEKLY:
            enableSaveConfig.value =
              !!newAutomatePayout.weeklyDays &&
              newAutomatePayout.weeklyDays.length > 0;
            break;
          case PortalServiceTypes.balancePayoutFrequency.MONTHLY:
            enableSaveConfig.value = !!newAutomatePayout.monthlyDate;
            break;
          case PortalServiceTypes.balancePayoutFrequency.YEARLY:
            enableSaveConfig.value = !!newAutomatePayout.yearlyDate;
            break;
          default:
            enableSaveConfig.value = false;
        }
      }

      if (enableSaveConfig.value) {
        if (newAutomatePayout.endPayout) {
          switch (newAutomatePayout.endPayout) {
            case PortalServiceTypes.balancePayoutEndType.NEVER:
              enableSaveConfig.value = true;
              break;
            case PortalServiceTypes.balancePayoutEndType.AFTER_PAYOUTS:
              enableSaveConfig.value = !!automatePayout.endPayoutAfter;
              break;
            case PortalServiceTypes.balancePayoutEndType.ON_DATE:
              enableSaveConfig.value = !!automatePayout.endPayoutDate;
              break;
            default:
              enableSaveConfig.value = false;
          }
        } else {
          enableSaveConfig.value = false;
        }
      }
    }
  });

  function hideLowBalanceBanner() {
    userDismissedBanner.value = true;
  }

  function hideReviewWalletBanner(address: string) {
    reviewBannersDismissed.value[address] = true;
  }

  function setPartnerState(newPartner: PortalServiceTypes.Partner) {
    partner.value = newPartner;
  }

  function displayPayoutSettings() {
    if (automatePayout?.frequency) {
      switch (automatePayout.frequency) {
        case PortalServiceTypes.balancePayoutFrequency.DAILY:
          repeatsOn.value = '';
          break;
        case PortalServiceTypes.balancePayoutFrequency.WEEKLY:
          repeatsOn.value = automatePayout?.weeklyDays?.join(',') ?? '';
          break;
        case PortalServiceTypes.balancePayoutFrequency.MONTHLY:
          repeatsOn.value = automatePayout.monthlyDate?.toString() ?? '';
          break;
        case PortalServiceTypes.balancePayoutFrequency.YEARLY:
          repeatsOn.value = `${automatePayout.yearlyDate?.month} ${automatePayout.yearlyDate?.day}`;
          break;
        default:
          repeatsOn.value = '';
      }
    }

    if (automatePayout?.endPayout) {
      switch (automatePayout.endPayout) {
        case PortalServiceTypes.balancePayoutEndType.AFTER_PAYOUTS:
          repeatEnds.value = `After ${
            automatePayout.endPayoutAfter ?? ''
          } Payouts`;
          break;
        case PortalServiceTypes.balancePayoutEndType.NEVER:
          repeatEnds.value = automatePayout.endPayout;
          break;
        case PortalServiceTypes.balancePayoutEndType.ON_DATE:
          repeatEnds.value = `On ${automatePayout.endPayoutDate ?? ''}`;
          break;
        default:
          repeatEnds.value = '';
      }
    }
  }

  async function addNewIPAddress() {
    try {
      if (!user.value || !user.value.token) {
        throw new Error('cannot create ip address if user is undefined');
      }
      await PortalService.CreateIPAddress(user.value.token, {
        ipAddress: newIpAddress.value,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  }

  async function deletePartnerIPAddress() {
    try {
      if (!user.value || !user.value.token) {
        throw new Error('cannot retrieve ip addresses if user is undefined');
      }
      await PortalService.DeleteIPAddress(
        user.value.token,
        deleteIPAddress.value!,
      );
    } catch (e: any) {
      throw new Error(e);
    }
  }

  async function fetchIPAddresses() {
    try {
      if (!user.value || !user.value.token) {
        throw new Error('cannot retrieve ip addresses if user is undefined');
      }
      const { ipAddresses } = await PortalService.GetIPAddresses(
        user.value.token,
      );
      partnerIPAddresses.value = ipAddresses;
      displayPartnerIPAddresses.value = partnerIPAddresses.value.map(
        ipAddress => ({
          'IP Address': ipAddress,
          Status: 'Whitelisted',
          Action: '',
        }),
      );
    } catch (e: any) {
      throw new Error(e);
    }
  }

  async function fetchApiKeys() {
    try {
      if (!user.value || !user.value.token) {
        throw new Error(
          'cannot retrieve partner api keys if user is undefined',
        );
      }
      const apiKeysResponse = await PortalService.FetchApiKeys(
        user.value.token,
      );
      partnerApiKeys.value = apiKeysResponse.sort(
        (key1, key2) =>
          new Date(key2.updatedAt).getTime() -
          new Date(key1.updatedAt).getTime(),
      );
    } catch (e: any) {
      throw new Error(e);
    }
  }

  async function updateApiKey(apiKey: PortalServiceTypes.ApiKey) {
    try {
      if (!user.value || !user.value.token) {
        throw new Error('cannot update api key status if user is undefined');
      }
      await PortalService.UpdateApiKey(user.value.token, apiKey);
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async function setAutomatedPayoutConfig() {
    try {
      if (!user.value || !user.value.token) {
        throw new Error('cannot retrieve partner if user is undefined');
      }
      if (!automatePayout) {
        throw new Error('Payout request object not set');
      }
      const automatedPayoutResponse =
        await PortalService.setAutomatedPayoutConfig(
          user.value.token,
          automatePayout,
        );
      setAutomatedPayoutConfigState(automatedPayoutResponse.payoutConfig);
    } catch (e: any) {
      throw new Error(e);
    }
  }

  function resetFeatureFlags() {
    apiKeysManagementEnabled.value = false;
    walletManagementEnabled.value = false;
    enabledCountriesEnabled.value = false;
    whitelistedIpsEnabled.value = false;
    channelsCoverageEnabled.value = false;
    webhooksManagementEnabled.value = false;
  }

  function DetermineAndSetPartnerAccess(featureFlags: FeatureFlags[]) {
    featureFlags.forEach(flag => {
      switch (flag) {
        case FeatureFlags.PARTNERS_DASHBOARD_API_KEYS:
          apiKeysManagementEnabled.value = true;
          break;
        case FeatureFlags.PARTNERS_DASHBOARD_WALLET_MANAGEMENT:
          walletManagementEnabled.value = true;
          break;
        case FeatureFlags.PARTNERS_DASHBOARD_ENABLED_COUNTRIES:
          enabledCountriesEnabled.value = true;
          break;
        case FeatureFlags.PARTNERS_DASHBOARD_WHITELISTED_IPS:
          whitelistedIpsEnabled.value = true;
          break;
        case FeatureFlags.PARTNERS_DASHBOARD_CHANNELS_COVERAGE:
          channelsCoverageEnabled.value = true;
          break;
        case FeatureFlags.PARTNERS_DASHBOARD_WEBHOOKS_MANAGEMENT:
          webhooksManagementEnabled.value = true;
          break;
        default:
          break;
      }
    });
  }

  async function updatePartnerRatesStatuses() {
    try {
      if (!user.value || !user.value.token) {
        throw new Error('cannot update partner rates if user is undefined');
      }
      const payload = statusChangedList.value.map(rate => ({
        partnerRateId: rate.id,
        status: rate.status,
        platformRateId: rate.rateId,
      }));
      await PortalService.UpdatePartnerRatesStatuses(user.value.token, {
        rates: payload,
      });
    } catch (e: any) {
      throw new Error(`Couldn't update partner rates`);
    }
  }

  async function reviewPartnerRateStatusChanges(
    payload: PortalServiceTypes.UpdatePartnerRateStatusObject[],
  ) {
    try {
      if (!user.value || !user.value.token) {
        throw new Error('cannot update partner rates if user is undefined');
      }
      await PortalService.UpdatePartnerRatesStatuses(user.value.token, {
        rates: payload,
      });
    } catch (e: any) {
      throw new Error(`Couldn't review partner rate status changes`);
    }
  }

  function hideCountryStatusChangeBanner() {
    countryStatusChangeBannerHidden.value = true;
  }

  async function fetchPartnerRates() {
    try {
      if (!user.value || !user.value.token) {
        throw new Error('cannot retrieve partner rates if user is undefined');
      }

      const { rates } = await PortalService.GetPartnerRates(user.value.token);
      partnerRates.value = rates.filter(
        rate =>
          rate.locale !== 'crypto' &&
          rate.code !== 'USD' &&
          rate.locale !== 'US',
      );
      showPendingCountriesBanner.value =
        isB2BPartner.value &&
        partnerRates.value.find(rate =>
          [
            PortalServiceTypes.PartnerRateStatus.PENDING_DISABLE.toString(),
            PortalServiceTypes.PartnerRateStatus.PENDING_ENABLE.toString(),
          ].includes(rate.status),
        ) !== undefined;
    } catch (e: any) {
      throw new Error(`Couldn't fetch partner rates`);
    }
  }

  function setPartnerProductType() {
    if (
      partner.value?.productTypes.includes(PortalServiceTypes.ProductTypes.B2B)
    ) {
      isB2BPartner.value = true;
    }

    if (
      partner.value?.productTypes.includes(
        PortalServiceTypes.ProductTypes.COMMERCIAL,
      )
    ) {
      isCommercialPartner.value = true;
    }
  }

  async function GetPartner() {
    try {
      if (!user.value || !user.value.token) {
        throw new Error('cannot retrieve partner if user is undefined');
      }

      const { partner: getPartnerResponse, featureFlags } =
        await PortalService.GetProfile(user.value.token);
      if (featureFlags && featureFlags.length)
        DetermineAndSetPartnerAccess(featureFlags);
      setPartnerState(getPartnerResponse);
      setPartnerProductType();
    } catch (e: any) {
      throw new Error(`could not retrieve partner : ${e.message}`);
    }
  }

  async function setPartnerLowBalance() {
    if (!user.value || !user.value.partnerId || !user.value.token) {
      throw new Error('cannot retrieve data without user, partnerId and token');
    }

    if (!partner.value?.lowBalanceLimit) {
      throw new Error(
        `Invalid low balance limit value : ${partner.value?.lowBalanceLimit}`,
      );
    }

    const updateLowLimitRequest = {
      partnerId: partner.value.id,
      lowBalanceLimit: partner.value?.lowBalanceLimit,
    };

    try {
      const lowBalanceLimitResponse =
        await PortalService.SetPartnerLowBalanceLimit(
          user.value.token,
          updateLowLimitRequest,
        );
      setPartnerState(lowBalanceLimitResponse.partner);
    } catch (e: any) {
      throw new Error(e);
    }
  }

  async function VerifyOtpCode(otpCode: string) {
    try {
      if (!user.value || !user.value.token || !partner.value) {
        throw new Error('cannot send otp without user and partner data');
      }

      return await PortalService.VerifyOtpCode(user.value.token, otpCode);
    } catch (e: any) {
      throw new Error(`could not generate otp: ${e.message}`);
    }
  }

  async function SendOtp() {
    try {
      if (!user.value || !user.value.token || !partner.value) {
        throw new Error('cannot send otp without user and partner data');
      }

      await PortalService.SendOtp(user.value.token);
    } catch (e: any) {
      throw new Error(`could not generate otp: ${e.message}`);
    }
  }

  async function CreateWallet(
    address: string,
    network: string,
    asset: string,
    tag?: string,
  ) {
    try {
      if (!user.value || !user.value.token || !partner.value) {
        throw new Error('cannot create wallet without user and partner data');
      }
      await PortalService.CreateWalletAddress(user.value.token, {
        address,
        network,
        tag,
        currencies: [asset],
      });
    } catch (e: any) {
      throw new Error(`could not create wallet address: ${e.message}`);
    }
  }

  async function DeleteWalletAddress(
    address: string,
    reason: string,
    token: string,
  ) {
    try {
      if (!user.value || !user.value.token || !partner.value) {
        throw new Error('cannot create wallet without user and partner data');
      }

      await PortalService.DeleteWalletAddress(user.value.token, address, {
        deleteReason: reason,
        token,
      });
    } catch (e: any) {
      throw new Error(`could not delete wallet address: ${e.message}`);
    }
  }

  async function ProcessSettlement(
    amount: number,
    sequenceId: string,
    wallet: PortalServiceTypes.Address,
  ) {
    try {
      if (!user.value || !user.value.token || !partner.value) {
        throw new Error(
          'cannot process settlement without user and partner data',
        );
      }

      await PortalService.ProcessSettlement(
        {
          amount,
          source: 'dashboard',
          sequenceId,
          walletAddress: wallet.address,
          cryptoCurrency: wallet.cryptocurrency,
          cryptoNetwork: wallet.network,
        },
        user.value.token,
      );
    } catch (e: any) {
      throw new Error(`could not process settlement: ${e.message}`);
    }
  }

  return {
    partner,
    showLowBalanceAlert,
    GetPartner,
    ProcessSettlement,
    resetPartnerStore,
    hideLowBalanceBanner,
    setPartnerLowBalance,
    setShowLowBalanceAlert,
    resetLowBalanceBanner,
    userDismissedBanner,
    hideReviewWalletBanner,
    reviewBannersDismissed,
    resetReviewWalletBanner,
    setAutomatedPayoutConfig,
    automatePayout,
    repeatEnds,
    repeatsOn,
    initialiseAutomatePayout,
    setPartnerState,
    displayPayoutSettings,
    user,
    enableSaveConfig,
    resetPayoutConfig,
    setAutomatedPayoutConfigState,
    fetchApiKeys,
    partnerApiKeys,
    loading,
    updateApiKey,
    walletManagementEnabled,
    channelsCoverageEnabled,
    apiKeysManagementEnabled,
    webhooksManagementEnabled,
    enabledCountriesEnabled,
    whitelistedIpsEnabled,
    CreateWallet,
    DeleteWalletAddress,
    SendOtp,
    VerifyOtpCode,
    fetchIPAddresses,
    newIpAddress,
    addNewIPAddress,
    partnerIPAddresses,
    deleteIPAddress,
    deletePartnerIPAddress,
    displayPartnerIPAddresses,
    fetchPartnerRates,
    partnerRates,
    showPendingCountriesBanner,
    selectedCountries,
    statusChangedList,
    updatePartnerRatesStatuses,
    reviewPartnerRateStatusChanges,
    countryStatusChangeBannerHidden,
    hideCountryStatusChangeBanner,
    resetFeatureFlags,
    isCommercialPartner,
    isB2BPartner,
    resetProductTypes,
  };
});

export default usePartnerStore;
