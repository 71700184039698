import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import HomeView from '@/views/HomeView.vue';
import { useAuthStore } from '@/store/modules/auth';
import { usePartnerStore } from '@/store/modules/partner';
import { storeToRefs } from 'pinia';
import { RouteNames, RoutePaths } from './route-details';

const routes: Array<RouteRecordRaw> = [
  {
    path: RoutePaths.Home,
    redirect: { path: '/transactions' },
    name: RouteNames.Home,
    component: HomeView,
    children: [
      {
        path: RoutePaths.Transactions,
        name: RouteNames.Transactions,
        component: () => import('@/views/dashboard/TransactionsView.vue'),
      },
      {
        path: RoutePaths.Balance,
        name: RouteNames.Balance,
        component: () => import('@/views/dashboard/BalanceView.vue'),
      },
      {
        path: RoutePaths.Trades,
        name: RouteNames.Trades,
        component: () => import('@/views/commercial/TradesView.vue'),
      },
      {
        path: RoutePaths.EnabledCountries,
        name: RouteNames.EnabledCountries,
        component: () => import('@/views/dashboard/EnabledCountries.vue'),
      },
      {
        path: RoutePaths.Coverage,
        name: RouteNames.Coverage,
        component: () => import('@/views/dashboard/CoverageView.vue'),
      },
      {
        path: RoutePaths.APIKeys,
        name: RouteNames.APIKeys,
        component: () => import('@/views/dashboard/APIKeyView.vue'),
      },
      {
        path: RoutePaths.Settings,
        name: RouteNames.Settings,
        component: () => import('@/views/dashboard/SettingsView.vue'),
      },
      {
        path: RoutePaths.WalletManagement,
        name: RouteNames.WalletManagement,
        component: () => import('@/views/dashboard/WalletManagementView.vue'),
      },
      {
        path: RoutePaths.Trades,
        name: RouteNames.Trades,
        component: () => import('@/views/commercial/TradesView.vue'),
      },
      {
        path: RoutePaths.Webhooks,
        name: RouteNames.Webhooks,
        component: () => import('@/views/dashboard/WebhooksView.vue'),
      },
    ],
  },
  {
    path: RoutePaths.Login,
    name: RouteNames.Login,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/authentication/LoginView.vue'),
  },
  {
    path: RoutePaths.ResetPassword,
    name: RouteNames.ResetPassword,
    component: () => import('@/views/authentication/ResetPasswordView.vue'),
  },
  {
    path: RoutePaths.TwoFactorAuthentication,
    name: RouteNames.TwoFactorAuthentication,
    component: () =>
      import('@/views/authentication/TwoFactorAuthenticationView.vue'),
  },
  {
    path: RoutePaths.PasswordChanged,
    name: RouteNames.PasswordChanged,
    component: () => import('@/views/authentication/PasswordChangedView.vue'),
  },
  {
    path: RoutePaths.AccountInactive,
    name: RouteNames.AccountInactive,
    component: () => import('@/views/authentication/AccountInactiveView.vue'),
  },
  {
    path: RoutePaths.RequestedPasswordReset,
    name: RouteNames.RequestedPasswordReset,
    component: () =>
      import('@/views/authentication/RequestedPasswordResetView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Routes accessible by admin users only
const adminRoutes = [RouteNames.APIKeys];

// Routes accessible by both admin and treasury roles
const treasuryRoutes = [RouteNames.WalletManagement];

// These routes are completely unprotected
const unProtectedRoutes = [
  RouteNames.Login,
  RouteNames.AccountInactive,
  RouteNames.RequestedPasswordReset,
];

// These routes can be seen if a user is logged in but not 2fa verified
const partiallyProtectedRoutes = [
  RouteNames.TwoFactorAuthentication,
  RouteNames.ResetPassword,
];

const commercialRoutes = [RouteNames.Trades];

const checkPartnerType = (route: string) => {
  const partnerStore = usePartnerStore();
  const { isB2BPartner, isCommercialPartner } = storeToRefs(partnerStore);
  if (
    unProtectedRoutes.includes(<RouteNames>route) ||
    partiallyProtectedRoutes.includes(<RouteNames>route)
  ) {
    return { nextCheck: true };
  }

  if (
    isCommercialPartner.value &&
    commercialRoutes.includes(<RouteNames>route)
  ) {
    return { nextCheck: true };
  }

  if (isB2BPartner.value && !commercialRoutes.includes(<RouteNames>route)) {
    return { nextCheck: true };
  }

  return {
    nextCheck: false,
    path: isB2BPartner.value ? '/transactions' : '/trades',
  };
};

const checkB2BFeatureFlags = (route: string) => {
  const partnerStore = usePartnerStore();
  const {
    walletManagementEnabled,
    apiKeysManagementEnabled,
    enabledCountriesEnabled,
    channelsCoverageEnabled,
  } = storeToRefs(partnerStore);

  switch (true) {
    case route === RouteNames.WalletManagement:
      return walletManagementEnabled.value;
    case route === RouteNames.APIKeys:
      return apiKeysManagementEnabled.value;
    case route === RouteNames.EnabledCountries:
      return enabledCountriesEnabled.value;
    case route === RouteNames.Coverage:
      return channelsCoverageEnabled.value;
    default:
      return true;
  }
};

const checkFeatureFlags = (route: string) => {
  const b2bFeatureFlagCheck = checkB2BFeatureFlags(route);
  return { nextCheck: b2bFeatureFlagCheck, path: '/' };
};

const checkAdminRoutes = (route: string) => {
  const auth = useAuthStore();
  const isAdminRoute = adminRoutes.includes(<RouteNames>route);

  if (
    unProtectedRoutes.includes(<RouteNames>route) ||
    partiallyProtectedRoutes.includes(<RouteNames>route)
  ) {
    return { nextCheck: true };
  }

  return {
    nextCheck: !isAdminRoute || (isAdminRoute && auth.isAdmin),
    path: '/',
  };
};

const checkTreasuryRoutes = (route: string) => {
  const auth = useAuthStore();
  const isTreasuryRoute = treasuryRoutes.includes(<RouteNames>route);
  const isAdminRoute = adminRoutes.includes(<RouteNames>route);

  if (
    unProtectedRoutes.includes(<RouteNames>route) ||
    partiallyProtectedRoutes.includes(<RouteNames>route)
  ) {
    return { nextCheck: true };
  }

  return {
    nextCheck:
      (!isTreasuryRoute && !isAdminRoute) ||
      (isAdminRoute && auth.isAdmin) ||
      (isTreasuryRoute && (auth.isTreasury || auth.isAdmin)),
    path: '/',
  };
};

const checkAuthentication = (route: string) => {
  const auth = useAuthStore();
  const isProtected =
    !unProtectedRoutes.includes(<RouteNames>route) &&
    !partiallyProtectedRoutes.includes(<RouteNames>route);
  const isPartiallyProtected = partiallyProtectedRoutes.includes(
    <RouteNames>route,
  );

  if (isProtected && !auth.verified) {
    if (isPartiallyProtected && auth.isLoggedIn) {
      return { nextCheck: true };
    }

    if (isPartiallyProtected && !auth.isLoggedIn) {
      return { nextCheck: false, path: '/login' };
    }

    return { nextCheck: false, path: '/login' };
  }

  if ((!isProtected || isPartiallyProtected) && auth.verified) {
    return { nextCheck: false, path: '/' };
  }
  return { nextCheck: true };
};

router.beforeEach((to, from, next) => {
  let checkResult = checkAuthentication(<string>to.name);
  if (checkResult.nextCheck) {
    checkResult = checkAdminRoutes(<string>to.name);

    if (checkResult.nextCheck) {
      checkResult = checkTreasuryRoutes(<string>to.name);

      if (checkResult.nextCheck) {
        checkResult = checkPartnerType(<string>to.name);

        if (checkResult.nextCheck) {
          checkResult = checkFeatureFlags(<string>to.name);

          if (checkResult.nextCheck) {
            next();
          }
        }
      }
    }
  }

  next({
    path: checkResult.path,
  });
});

export default router;
