import { PortalServiceTypes } from '@/types/portalService';
import { defineStore, storeToRefs } from 'pinia';
import { reactive, ref, watch } from 'vue';
import { usePartnerStore } from '@/store/modules/partner';
import useAuthService from '@/store/modules/auth';
import { useUiStore } from '@/store/modules/ui';
import PortalService from '@/common/services/portal.service';
import {
  currencyNetworkMap,
  getCurrencyNetwork,
  setCurrencyNetwork,
  supportedCoins,
  supportedNetworks,
} from '@/utils/helpers';

export const useWalletsStore = defineStore('wallets', () => {
  const partnerStore = usePartnerStore();
  const authStore = useAuthService();
  const uiStore = useUiStore();

  const { partner } = storeToRefs(partnerStore);
  const { user } = storeToRefs(authStore);
  const { loading, refreshDropdownKey } = storeToRefs(uiStore);

  const lastCurrencyNetwork = getCurrencyNetwork();
  const currencyNetwork = ref(lastCurrencyNetwork);
  const walletAddress = ref('');
  const addressTag = ref('');
  const displayCount = ref(0);
  const startIndex = ref(0);
  const walletsTableKey = ref(0);
  const isLoading = ref(false);

  const walletAddresses = reactive<PortalServiceTypes.Address[]>([]);
  const settlementWallets = reactive<PortalServiceTypes.PartnerWallet[]>([]);
  const settlementWalletAddresses = ref<PortalServiceTypes.Address[]>([]);
  const addressesPendingReview = ref<PortalServiceTypes.Address[]>([]);

  const shouldUpdateStatus = ref(true);
  const modalType = ref('');
  const selectedTopupAddress = ref<string | undefined>(undefined);
  const selectedTopupNetwork = ref<string | undefined>(undefined);
  const selectedTopupCoin = ref<string | undefined>(undefined);

  function findAddressAndTag(currency: string, network: string) {
    const currentAddress = walletAddresses.find(
      address =>
        address.cryptocurrency === currency.toUpperCase() &&
        address.network === network.toUpperCase(),
    );

    return { address: currentAddress?.address, tag: currentAddress?.tag };
  }

  const deleteWalletReasons = reactive<string[]>([
    'This wallet address is no longer used',
    'This wallet was compromised',
    'This wallet address was added by accident',
    'We are using this asset on a different network',
  ]);

  const denyWalletReasons = reactive<string[]>([
    'This is not a recognised wallet address',
    'We decided to use a different wallet address',
    'This wallet address was added by accident',
    'There is a mistake in this wallet address',
  ]);

  const networks: { value: string; label: string; icon: string }[] = reactive(
    [],
  );

  const coinList = ref(
    supportedCoins.map((coin: string) => ({
      icon: coin,
      label: coin,
      value: coin,
    })),
  );
  const networksList = ref([...supportedNetworks]);

  function getSelectedTopupCoin() {
    const filterCoin = selectedTopupCoin.value?.toLowerCase() ?? 'usdt';
    const [selectedCoin] = coinList.value.filter(
      coin => coin.label.toLowerCase() === filterCoin,
    );
    selectedTopupCoin.value = selectedCoin.label;
    return selectedCoin;
  }

  function getSelectedTopupNetwork() {
    if (!selectedTopupNetwork.value) {
      selectedTopupNetwork.value = 'TRC20';
    }
    refreshDropdownKey.value += 1;
    return selectedTopupNetwork.value;
  }

  function setWalletAddresses(data: any[]) {
    walletAddresses.splice(0);
    data.forEach(item => walletAddresses.push(item));
  }

  async function GetWalletAddresses() {
    try {
      if (!user.value || !user.value.token || !partner.value) {
        throw new Error(
          'cannot assign partner wallet address without partner and user',
        );
      }

      let { addresses } = await PortalService.GetWalletAddresses(
        user.value.token,
      );
      if (!addresses.length) {
        // assign usdt trc20 address if no wallets currently assigned
        const address = await PortalService.AssignDefaultPartnerWalletAddress(
          user.value.token,
        );
        addresses = [
          {
            id: address.walletAddress,
            cryptocurrency: 'USDT',
            network: 'TRC20',
            address: address.walletAddress,
            status: 'active',
          },
        ];
      }
      setWalletAddresses(addresses);
    } catch (e: any) {
      throw new Error(`could not assign partner wallet address: ${e.message}`);
    }
  }

  function getWalletAddress(
    currency: string,
    network: string,
    fromApi = false,
  ) {
    loading.value = true;
    if (!walletAddresses.length || fromApi) {
      GetWalletAddresses()
        .then(() => {
          const addressAndTag = findAddressAndTag(currency, network);
          walletAddress.value = addressAndTag.address as string;
          addressTag.value = addressAndTag.tag as string;
          loading.value = false;
        })
        .catch((e: any) => {
          loading.value = false;
          walletAddress.value = '';
          console.error(`could not fetch wallet addresses: ${e}`);
        });

      return;
    }

    const addressAndTag = findAddressAndTag(currency, network);
    walletAddress.value = addressAndTag.address as string;
    addressTag.value = addressAndTag.tag as string;
    loading.value = false;
  }

  watch(currencyNetwork, newValue => {
    if (!newValue) return;

    const [currency, network] = newValue.split('-');
    getWalletAddress(currency, network);
  });

  watch([selectedTopupCoin, selectedTopupNetwork], () => {
    getWalletAddress(
      selectedTopupCoin.value ?? '',
      selectedTopupNetwork.value ?? '',
      true,
    );
  });

  function setIndex(index: number) {
    startIndex.value = index;
  }

  function setPage() {
    if (settlementWalletAddresses.value.length <= 10) {
      setIndex(1);
    }
  }

  function setSettlementWallets(data: PortalServiceTypes.PartnerWallet[]) {
    settlementWalletAddresses.value.splice(0);
    addressesPendingReview.value.splice(0);
    data.forEach(wallet => {
      wallet.currencies.forEach(currency => {
        const address = {
          id: wallet.id,
          cryptocurrency: currency,
          network: wallet.network,
          address: wallet.address,
          status: wallet.status,
          createdBy: wallet.createdByName,
          memo: wallet.network.toLowerCase() === 'xlm' ? wallet.tag : undefined,
        };

        settlementWalletAddresses.value.push(address);

        if (address.status === 'pending_approval') {
          addressesPendingReview.value.push(address);
        }
      });
    });
    displayCount.value = settlementWalletAddresses.value.length;
  }

  async function GetSettlementWallets() {
    try {
      if (!user.value || !user.value.token || !partner.value) {
        throw new Error(
          'cannot get partner wallet addresses without partner and user',
        );
      }

      const { wallets } = await PortalService.GetSettlementWallets(
        user.value.token,
      );
      setSettlementWallets(wallets);
    } catch (e: any) {
      throw new Error(`could not fetch partner wallets: ${e.message}`);
    }
  }

  async function UpdateWalletStatus(
    address: string,
    status: string,
    reason?: string,
  ) {
    try {
      if (!user.value || !user.value.token) {
        throw new Error('cannot update partner wallet address without user');
      }

      await PortalService.UpdateWalletStatus(user.value.token, {
        address,
        status,
        reason,
      });
    } catch (e: any) {
      throw new Error(`could not update wallet status: ${e.message}`);
    }
  }

  const selectTopupNetwork = (value: string) => {
    selectedTopupNetwork.value = value;
  };

  const selectTopupCoin = (item: {
    value: string;
    label: string;
    icon: string;
  }) => {
    const coinNetworks = Object.keys(currencyNetworkMap)
      .filter(key => key.startsWith(item.value.toLowerCase()))
      .map(key => key.split('-')[1].toUpperCase());
    selectedTopupCoin.value = item.value;
    let selectedNetwork;
    if (
      selectedTopupNetwork.value &&
      coinNetworks.includes(selectedTopupNetwork.value)
    ) {
      selectedNetwork = selectedTopupNetwork.value;
    }
    networksList.value = coinNetworks;
    selectTopupNetwork(selectedNetwork ?? '');
    refreshDropdownKey.value += 1;
  };

  const currencyNetworkSelect = (item: {
    value: string;
    label: string;
    icon: string;
  }) => {
    currencyNetwork.value = item.value || lastCurrencyNetwork;
    setCurrencyNetwork(item.value);
  };

  return {
    walletAddress,
    walletAddresses,
    settlementWallets,
    currencyNetwork,
    networks,
    currencyNetworkSelect,
    getWalletAddress,
    GetWalletAddresses,
    GetSettlementWallets,
    settlementWalletAddresses,
    displayCount,
    addressesPendingReview,
    deleteWalletReasons,
    shouldUpdateStatus,
    modalType,
    UpdateWalletStatus,
    denyWalletReasons,
    startIndex,
    setIndex,
    walletsTableKey,
    addressTag,
    selectedTopupAddress,
    selectedTopupNetwork,
    selectedTopupCoin,
    selectTopupCoin,
    selectTopupNetwork,
    coinList,
    networksList,
    getSelectedTopupCoin,
    getSelectedTopupNetwork,
    isLoading,
    setPage,
  };
});

export default useWalletsStore;
