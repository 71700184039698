export namespace TradeTypes {
  type NumberString = string | number;
  export const tradeTableHeaders = [
    'date',
    'trade id',
    'country',
    'you sold',
    'you received',
    'rate including fee',
    'action',
  ];

  export interface TradeDetails {
    'trade id': string;
    'partner id': string;
    date: string;
    country: string;
    'fiat currency': string;
    cryptocurrency: string;
    network: string;
    'effective rate': NumberString;
    'quoted rate': NumberString;
    fee: NumberString;
    type: NumberString;
    'fiat amount': NumberString;
    'crypto amount': NumberString;
    action?: string;
    'you sold': NumberString;
    'you received': NumberString;
    'rate including fee': NumberString;
    cryptoSold?: NumberString;
    fiatSold?: NumberString;
    cryptoBought?: NumberString;
    fiatBought?: NumberString;
  }

  export interface TradesResponse {
    id: string;
    tradeId: string;
    partnerId: string;
    tradeLockedDate: string;
    country: string;
    fiatCurrency: string;
    cryptoCurrency: string;
    network: string;
    effectiveRate: number;
    quotedRate: number;
    fee: number;
    cryptoAmount: number;
    fiatAmount: number;
    cryptoBought: number;
    cryptoSold: number;
    fiatSold: number;
    fiatBought: number;
    tradeType: string;
    cryptoTransactionType: string;
  }

  export enum Filters {
    COUNTRY = 'Country of Trade',
    COIN = 'Cryptocurrency',
    FIAT = 'Local Currency',
    AMOUNT = 'Amount',
  }

  export interface RangeFilter {
    min: number;
    max: number;
  }

  export interface FilterCategory {
    name: Filters;
    options: string[];
    filter: RangeFilter | string;
  }

  export interface FilterQuery {
    country?: string;
    cryptoCurrency?: string;
    localCurrency?: string;
    amountRange?: number[];
    dateRange?: string[];
    startIndex?: number;
  }

  export interface TradeSearchResult {
    data: any[];
    total: number;
  }

  export interface TradesQuery {
    startIndex: number;
    size: number;
    idFilter: string;
    range: {
      startDate: string;
      endDate: string;
    };
    amount: {
      min: number;
      max: number;
    };
  }
}
