import { TransactionTypes } from '@/types/tables/transactions';
import { formatDate, titleCase } from '@/utils/helpers';
import { SettlementTypes } from '@/types/tables/settlements';
import { CollectionTypes } from '@/types/tables/collections';
import { PaymentTypes } from '@/types/tables/payments';
import { FeeTypes } from '@/types/tables/fees';
import { TradeTypes } from '@/types/tables/trades';

export const flattenObject = (obj: any, prefix = '') => {
  const flattened: any = {};

  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key], key));
    } else {
      flattened[prefix + key] = obj[key];
    }
  });
  return flattened;
};

export const renameKeys = (keysMap: any, obj: any) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] },
    }),
    {},
  );

export const isolateWantedParameters = (
  originalArray: any,
  headers: string[],
) =>
  originalArray.map((element: any) => {
    const allKeys = Object.keys(element);
    return allKeys.reduce((next, key) => {
      if (headers.includes(key)) {
        return { ...next, [key]: element[key] };
      }
      return next;
    }, {});
  });

export const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 8,
});

export const prepareTableData = (
  data: any[],
  rawHeaders: string[],
  headerMapping: any,
) => {
  const flattenedArray = data.map((element: any) => flattenObject(element));

  const reducedDataArray = isolateWantedParameters(flattenedArray, rawHeaders);
  return reducedDataArray.map((element: any) =>
    renameKeys(headerMapping, element),
  );
};

export const prepareTransactionData = (
  data: any[],
  table: TransactionTypes.TransactionTable,
  allData = false,
) => {
  let refactoredData: any;
  switch (table) {
    case TransactionTypes.TransactionTable.PAYMENTS:
      refactoredData = prepareTableData(
        data,
        PaymentTypes.rawHeaders,
        PaymentTypes.headerMapping,
      );
      refactoredData.map((element: any) => {
        element.convertedAmount = formatter.format(element.convertedAmount);
        element.Amount = `${element.Currency} ${element.convertedAmount}`;
        element.Status = titleCase(element.Status);
        element['Payment Method'] = titleCase(element['Payment Method']);
        if (!allData) element.SortingDate = element.Date;
        return element;
      });
      break;
    case TransactionTypes.TransactionTable.COLLECTIONS:
      refactoredData = prepareTableData(
        data,
        CollectionTypes.rawHeaders,
        CollectionTypes.headerMapping,
      );
      refactoredData.map((element: any) => {
        element.convertedAmount = element.convertedAmount.toFixed(2);
        element.convertedAmount = formatter.format(element.convertedAmount);
        element.Amount = `${element.Currency} ${element.convertedAmount}`;
        element.Status = titleCase(element.Status);
        if (
          element['Request Source'] === null ||
          element['Request Source'] === undefined
        )
          element['Request Source'] = 'API';
        element['Request Source'] = titleCase(element['Request Source']);
        if (element['Request Source'] === 'Api')
          element['Request Source'] = 'API';
        element['Payment Method'] = titleCase(element['Payment Method']);
        if (!allData) element.SortingDate = element.Date;
        return element;
      });
      break;
    case TransactionTypes.TransactionTable.SETTLEMENTS:
      refactoredData = prepareTableData(
        data,
        SettlementTypes.rawHeaders,
        SettlementTypes.headerMapping,
      );
      refactoredData.map((element: any) => {
        element.fiatAmountUSD = element.fiatAmountUSD.toFixed(2);
        element.fiatAmountUSD = formatter.format(element.fiatAmountUSD);
        element.cryptoAmount = parseFloat(element.cryptoAmount.toFixed(8));
        element.cryptoAmount = formatter.format(element.cryptoAmount);
        element.cryptoRate = formatter.format(element.cryptoRate);
        element.Amount = `${element.cryptoCurrency} ${element.cryptoAmount}`;
        element.Status = titleCase(element.Status);
        element['Transaction type'] =
          SettlementTypes.TransactionTypeMapping[
            element[
              'Transaction type'
            ] as keyof typeof SettlementTypes.TransactionTypeMapping
          ];
        return element;
      });
      break;
    case TransactionTypes.TransactionTable.FEES:
      refactoredData = prepareTableData(
        data,
        FeeTypes.rawHeaders,
        FeeTypes.headerMapping,
      );
      refactoredData.map((element: any) => {
        element.Amount = `USD ${formatter.format(element.amountUSD)}`;
        element['Transaction Type'] = titleCase(element['Transaction Type']);
        element['Payment Method'] = titleCase(element['Payment Method']);
        return element;
      });
      break;
    default:
      throw new Error(`cannot perform data cleaning for table : ${table}`);
  }
  refactoredData.map((element: any) => {
    if (!allData) element.View = 'View';
    if (!allData) element.Date = formatDate(element.Date);
    return element;
  });

  return refactoredData;
};

const sortFunction = (a: any, b: any) => {
  const dateA = new Date(a.SortingDate).getTime();
  const dateB = new Date(b.SortingDate).getTime();
  return dateA > dateB ? 1 : -1;
};

export const mergeAndSortData = (
  array1: any[],
  array2: any[],
  total: boolean,
) => {
  const combined = [...array1, ...array2];
  const sorted = combined.sort(sortFunction);
  sorted.reverse();
  if (combined.length > 10 && !total) {
    return sorted.slice(0, 10);
  }
  return sorted;
};

export const getCount = (
  data: any[],
  activeElement: TransactionTypes.TransactionTable,
) =>
  data.reduce((elements, arr) => {
    if (arr.Type === activeElement) {
      return elements + 1;
    }
    return elements;
  }, 0);

export const getUniqueElements = (
  data: any[],
  parameter: TransactionTypes.Filters,
): string[] => [...new Set(data.map(element => element[parameter]))];

export const addToUniqueArray = (
  originalArray: string[],
  elementsToAdd: string[],
): string[] => [...new Set(originalArray.concat(elementsToAdd))];

export const prepareMatchQueryParameter = (
  partnerId: string,
  filterRecords:
    | TransactionTypes.FilterCategory[]
    | TradeTypes.FilterCategory[],
  table: TransactionTypes.DisplayTable,
): any[] => {
  const matchArray: any[] = [{ term: { 'partnerId.keyword': partnerId } }];

  let statusFilter = '';
  let countryFilter = '';
  let paymentMethodsFilter = '';
  let transactionTypesFilter = '';
  let requestSourceFilter = '';
  let cryptoCurrencyFilter = '';
  let fiatCurrencyFilter = ''

  switch (table) {
    case TransactionTypes.DisplayTable.TRADES:
      countryFilter = (filterRecords as TradeTypes.FilterCategory[]).filter(
        category => category.name === TradeTypes.Filters.COUNTRY,
      )[0].filter as string;
      cryptoCurrencyFilter = (filterRecords as TradeTypes.FilterCategory[]).filter(
        category => category.name === TradeTypes.Filters.COIN
      )[0].filter as string;
      fiatCurrencyFilter = (filterRecords as TradeTypes.FilterCategory[]).filter(
        category => category.name === TradeTypes.Filters.FIAT
      )[0].filter as string;
      break;
    case TransactionTypes.DisplayTable.PAYMENTS:
      statusFilter = (
        filterRecords as TransactionTypes.FilterCategory[]
      ).filter(category => category.name === TransactionTypes.Filters.STATUS)[0]
        .filter;
      countryFilter = (
        filterRecords as TransactionTypes.FilterCategory[]
      ).filter(
        category => category.name === TransactionTypes.Filters.COUNTRY,
      )[0].filter;
      break;
    case TransactionTypes.DisplayTable.COLLECTIONS:
      statusFilter = (
        filterRecords as TransactionTypes.FilterCategory[]
      ).filter(category => category.name === TransactionTypes.Filters.STATUS)[0]
        .filter;
      countryFilter = (
        filterRecords as TransactionTypes.FilterCategory[]
      ).filter(
        category => category.name === TransactionTypes.Filters.COUNTRY,
      )[0].filter;
      requestSourceFilter =
        (filterRecords as TransactionTypes.FilterCategory[]).filter(
          category => category.name === TransactionTypes.Filters.REQUEST_SOURCE,
        )[0]?.filter ?? '';
      break;
    case TransactionTypes.DisplayTable.SETTLEMENTS:
      statusFilter = (
        filterRecords as TransactionTypes.FilterCategory[]
      ).filter(category => category.name === TransactionTypes.Filters.STATUS)[0]
        .filter;
      break;
    case TransactionTypes.DisplayTable.FEES:
      paymentMethodsFilter = (
        filterRecords as TransactionTypes.FilterCategory[]
      ).filter(
        category => category.name === TransactionTypes.Filters.PAYMENT_METHOD,
      )[0].filter;
      transactionTypesFilter = (
        filterRecords as TransactionTypes.FilterCategory[]
      ).filter(
        category => category.name === TransactionTypes.Filters.TRANSACTION_TYPE,
      )[0].filter;
      break;
    default:
      statusFilter = (
        filterRecords as TransactionTypes.FilterCategory[]
      ).filter(category => category.name === TransactionTypes.Filters.STATUS)[0]
        .filter;
      countryFilter = (
        filterRecords as TransactionTypes.FilterCategory[]
      ).filter(
        category => category.name === TransactionTypes.Filters.COUNTRY,
      )[0].filter;
      break;
  }

  if (statusFilter !== '') {
    matchArray.push({ term: { 'status.keyword': statusFilter.toLowerCase() } });
  }
  if (countryFilter !== '') {
    matchArray.push({ term: { 'country.keyword': countryFilter } });
  }
  if (transactionTypesFilter !== '') {
    matchArray.push({
      term: { 'transactionType.keyword': transactionTypesFilter.toLowerCase() },
    });
  }
  if (paymentMethodsFilter !== '') {
    matchArray.push({
      term: { 'paymentMethod.keyword': paymentMethodsFilter.toLowerCase() },
    });
  }
  if (requestSourceFilter !== '') {
    matchArray.push({
      term: { 'requestSource.keyword': requestSourceFilter.toLowerCase() },
    });
  }
  if (cryptoCurrencyFilter !== '') {
    matchArray.push({ term: { 'cryptoCurrency.keyword': cryptoCurrencyFilter } });
  }
  if (fiatCurrencyFilter !== '') {
    matchArray.push({ term: { 'fiatCurrency.keyword': fiatCurrencyFilter } });
  }
  return matchArray;
};

export default {};
